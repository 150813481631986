<template>
  <!-- Customer -->
  <div class="box-card" v-bind:class="{'mobile-view':isMobileScreen}">
    <div  class="Topinp flex justify-end" v-if="isShowTopinpBar">
      <!-- <div class="Topinput pb-2 laptop:pb-2 desktop:pb-3 pt-2 laptop:pt-1 pr-2"> -->
      <div class="Topinput pb-2 laptop:pb-2 desktop:pb-3 pt-2 laptop:pt-1 pr-2">
        <el-input
          :size="isMobileScreen?'mini':'small'"
          :placeholder="$t('btn.search')"
          v-model="Tankinput"
          @keyup.enter.native="Tankinputval(Tankinput)"
          style="width: 100%;"
        >
          <span slot="suffix" @click="Tankinputval(Tankinput)">
            <svg-icon iconClass="search" />
          </span>
        </el-input>
        <el-select
          :size="isMobileScreen?'mini':'small'"
          v-model="Selectvalue"
          @change="handleSelectChange"
          :placeholder="$t('btn.' +Pendingoptions[0].label)"
          style="width: 100%; margin-left: 20px"
        >
          <el-option
            v-for="item in Pendingoptions"
            :key="item.value"
            :label="$t('btn.' + item.label)"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button style="margin-left: 16px" class="refreshBtn" size="small" @click="handleRefresh">
          <i :class="loading ? 'el-icon-refresh rotating' : 'el-icon-refresh'"></i>
        </el-button>
      </div>
    </div>
    <div class="Comtable" ref="tableRef" v-bind:class="{'dynamicHeight':showCbnButton}">
      <el-table
        :data="orderList"
        :cell-style="getCellStyle"
        :header-cell-style="{
          height: '55px',
          background: '#fff',
          'border-bottom': '2px solid #D6D9E1',
        }"
        table-layout="auto"
        @row-click="Tanbleclick"
        height="100%"
      >
        <el-table-column 
          prop="id"
          align="left"
          label="ID"
          :min-width="isMobileScreen?'50':'100'"
          sortable
        >
        </el-table-column>
        <el-table-column 
          prop="noEta"
          align="left"
          :label="$t('table.ETA')"
          :min-width="isMobileScreen?'50':'100'"
          :sortable="true"
          :sort-method='(a,b)=>sortByDate(a,b,"noEta")'
        >
          <template slot-scope="scope">
            <div :style="scope.row.noEtaChanged ? { color: 'red' } : {}" v-html="breakDateTime(scope.row.noEta)"></div>
          </template>
        </el-table-column>
        <el-table-column
          sortable
          prop="blockNomination"
          align="left"
          :label="$t('common.status')"
          :min-width="isMobileScreen?'70':'100'"
        >
          <template slot-scope="scope">
            <div :style="scope.row.blockNominationChanged ? { color: 'red' } : {}">
            {{ scope.row["blockNomination"] == '1' ? "Blocked" : (scope.row["blockNomination"] == '2' ? "Booked":"") }}
            </div>
          </template>
        </el-table-column>
         <el-table-column 
          align="left"
          prop="bargeReadiness"
          :label="$t('table.bargeReadiness')"
          :min-width="isMobileScreen?'120':'150'"
          :sortable="true"
          :sort-method='(a,b)=>sortByDate(a,b,"bargeReadiness")'
        >
          <!-- <template slot="header">
            Barge<br>Readiness
          </template> -->
          <template slot-scope="scope">
            <div :style="scope.row.bargeReadinessChanged ? { color: 'red' } : {}" v-html="breakDateTime(scope.row.bargeReadiness)"></div>
          </template>
        </el-table-column>
        <el-table-column 
          sortable
          prop="vesselName"
          align="left"
          :min-width="isMobileScreen?'100':'150'"
          class-name="bargename-header"
        >
          <template slot="header">
            Barge Name<br>IMO/LOA
          </template>
          <template slot-scope="scope">
            <div :style="scope.row.bargeNameChanged ? { color: 'red' } : {}">
              <div>{{ scope.row.vesselName }}</div>
              <div>{{ scope.row.bargeName }} / {{scope.row?.lengthOverall ?? ''}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column sortable
          align="left"
          prop="customerCompanyName"
          :label="$t('table.customer')"
          :min-width="isMobileScreen?'100':'130'"
        >
        </el-table-column>
       
        <el-table-column
          sortable
          prop="typeOfPilot"
          align="left"
          :label="$t('table.pilotExemption')"
          :min-width="isMobileScreen?'50':'80'"
        >
        <template slot="header">
          Pilot
        </template>
        <template slot-scope="scope">
          <div :style="scope.row.typeOfPilotChanged ? { color: 'red' } : {}">
            {{ scope.row.typeOfPilot }}
          </div>
        </template>
        </el-table-column>
        <el-table-column sortable
          prop="jettyNo"
          align="left"
          :label="$t('table.jetty')"
          :min-width="isMobileScreen?'60':'90'"
          :sort-method='(a,b)=>sortByNumStr(a,b,"jettyNo")'
        >
          <template slot-scope="scope">
            <el-select 
              @focus="handleFocus"
              @blur="handleBlur"
              v-if="userInfo.role == '2' && (scope.row.status == 1 || scope.row.status == 2) && (scope.row.loadingType == null || scope.row.loadingType < 4)"
              :disabled="scope.row.blockNomination == '1' || timerPickerFocus" 
              v-model="scope.row.jettyNo"
              @change="handleJettyOptionChange(scope.row)"
              placeholder=""  :size="isMobileScreen?'mini':'small'" :style="{ width: '100%' }">
              <el-option v-for="(item, index) in jettyNoOptios" :key="index" :label="item.berthName"
                :value="item.berthName">
              </el-option>
            </el-select>
            <span v-else :style="scope.row.jettyNoChanged ? { color: 'red' } : {}">
              {{ scope.row.jettyNo }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          align="left"
          prop="berthAssignTiming"
          :min-width="isMobileScreen?'160':'210'"
           :sortable="true"
          :sort-method='(a,b)=>sortByDate(a,b,"berthAssignTiming")'
        >
          <template slot="header">
            Berth Assign Timing
          </template>
          <template slot-scope="scope">
            <div 
              @click.stop
              v-if="userInfo.role == '2' && (orderIndex == 1 || orderIndex == 2)">
              <el-date-picker
                @focus="handleDateTimeFocus"
                @blur="handleDateTimeBlur"
                :size="isMobileScreen?'mini':'small'"
                :disabled="scope.row.blockNomination == '1' || selectFocus"
                class="dataPicker"
                :clearable="false"
                v-model="scope.row.berthAssignTiming" 
                prefix-icon="el-icon-date"
                format="yyyy/MM/dd HH:mm:ss" 
                value-format="dd/MM/yyyy HH:mm:ss"
                type="datetime" :style="{ width: '100%' }" 
                @change="Timehangder(scope.row)">
             </el-date-picker>
            </div>
            <span v-else>
              <div :style="scope.row.berthAssignTimingChanged ? { color: 'red' } : {}" v-html="breakDateTime(scope.row.berthAssignTiming)"></div>
            </span>
          </template>
        </el-table-column>
        <el-table-column sortable show-overflow-tooltip 
          align="left"
          prop="nominatedQtyMax"
           :label="$t('table.qty')"
          :min-width="isMobileScreen?'80':'100'"
        >
          <template slot-scope="scope">
            <div :style="scope.row.nominatedQtyMaxChanged ? { color: 'red' } : {}">
              {{ scope.row.nominatedQtyMax }}
            </div>
          </template>
        </el-table-column>
        <el-table-column sortable show-overflow-tooltip  prop="balanceInTank"
          align="left" 
          :label="$t('table.BAL')" 
          :min-width="isMobileScreen?'50':'100'"
        >
          <template slot-scope="scope">
            <div :style="scope.row.balanceInTankChanged ? { color: 'red' } : {}">
              {{ scope.row.balanceInTank }}
            </div>
          </template>
        </el-table-column>
        <el-table-column 
          v-if="userInfo.userType!=4"
          align="left" 
          :label="$t('table.tank')"
          :min-width="isMobileScreen?'60':'100'"
         >
          <template slot-scope="scope">
            <div :style="scope.row.tankChanged ? { color: 'red' } : {}">
              {{ getTanksOrSurveyorsColunmDisplay(scope.row.tlistNomination,'tankNumber') }}
            </div>
          </template>
        </el-table-column>
        <el-table-column sortable prop="product"
          align="left"
          :label="$t('table.Product')"
          :min-width="isMobileScreen?'90':'120'"
        >
          <template slot-scope="scope">
            <div :style="scope.row.productChanged ? { color: 'red' } : {}">
              {{ scope.row.product }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="left" :label="$t('table.SUR')"  :min-width="isMobileScreen?'60':'100'"> 
          <template slot-scope="scope">
            <div :style="scope.row.surveyorChanged ? { color: 'red' } : {}">
            {{ getTanksOrSurveyorsColunmDisplay(scope.row.slistNomination,'surveyorName') }}
            </div>
          </template>
        </el-table-column>
        <el-table-column sortable  prop="doc"
          align="left" 
          :label="$t('table.doc')" 
          :min-width="isMobileScreen?'50':'100'"
        >
          <template slot-scope="scope">
            <div :style="scope.row.docChanged ? { color: 'red' } : {}">
              {{ scope.row.doc }}
            </div>
          </template>
        </el-table-column>
        <el-table-column 
          prop="remarks"
          align="left"
          :label="$t('table.remarks')"
          :min-width="isMobileScreen?'100':'150'"
        >
          <template slot-scope="scope">
            <div :style="scope.row.remarksChanged ? { color: 'red' } : {}">{{ scope.row.remarks }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 查看弹窗 -->
    <el-dialog
      title="Ship AIS Information"
      :visible.sync="dialogVisible"
      width="700px"
    >
      <ship-tab-view :shipinfo="shipinfo" :vesselName="vesselName" />
    </el-dialog>
    <checkBargeDialog ref="checkBargeDialog" @confirm="handleSureConfirm" :message="message" :isMobileScreen="isMobileScreen" />
  </div>
</template>

<script>
import axios from "axios";
import ShipTabView from "@/view/barging/components/ship-tab-view.vue";
import { getSelectVessel } from "@/services/user";
import { JettyAll } from "@/services/user";
import { mapState } from "vuex";
import { Amendnomination } from "@/services/user";
import checkBargeDialog from "@/view/barging/components/check-barge-dialog.vue";
import { searchBargeBer } from '@/services/index';
import {convertDateTimeStrToDate, sortByDate, sortByNumStr } from "@/utils/tools";
export default {
  components: {
    ShipTabView,
    checkBargeDialog
  },
  props: ["orderList", "loading",'orderIndex','isShowTopinpBar', 'pilotTabs','showCbnButton'],
  data() {
    return {
      // tableData: [], //unused data
      Tankinput: "",
      Pendingoptions: [
        {
          value: null,
          label: "SelectAll",
        },
        {
          value: "1",
          label: "Today",
        },
        {
          value: "2",
          label: "ThisWeek",
        },
        {
          value: "3",
          label: "ThisMonth",
        },
        {
          value: "4",
          label: "ThisYear",
        },
      ],
      Selectvalue: "",
      dialogVisible: false,
      shipinfo: {},
      vesselName: "",
      bargeoptions: [],
      jettyNoOptios:[],
      selectFocus: false,
      timerPickerFocus:false,
      message:""
    };
  },
  created() {
    this.getData();
    this.getJettyList();

    // setTimeout(() => {
    //   this.setupEventListeners();
    // }, 2000);
  },
  computed:{
    ...mapState(["userInfo","screenType"]),
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    },
    dynamicHeightBargingCard(){
      if(this.isMobileScreen){
        return this.showCbnButton ? 'calc(100vh - 17rem)' : 'calc(100vh - 15rem)';
      }else{
        return this.showCbnButton ? 'calc(100vh - 17rem)' : 'calc(100vh - 15rem)';
      }
    }
  },
  methods: {
     convertDateTimeStrToDate,
     sortByDate,
     sortByNumStr,
    handleFocus() {
      console.log("select:focused");
      setTimeout(() => {
        this.$emit("focused");
        this.selectFocus = true;
      },300)
    },
    handleBlur() {
      console.log("select:blurred")
      setTimeout(() => {
        this.$emit("blurred");
        this.selectFocus = false;
      },300)
    },
    getTanksOrSurveyorsColunmDisplay(list,fieldName){
      let str = ''
      if (list?.length>0) {
        for (let i = 0; i < list.length; i++) {
          if (i == 0) {
            str = list[i][fieldName]
          }else{
            str += ' /'+list[i][fieldName]
          }  
        }
      }
      return str;
     
    },
    breakDateTime(dateTime) {
      return dateTime ? dateTime.replace(" ", "<br>") : dateTime;
    },
    handleSureConfirm() {
      if(this.effectCallback){
        this.effectCallback();
        this.$refs.checkBargeDialog.close();
      }
    },
    // input框
    Tankinputval() {
      this.$emit("updateList", this.Tankinput, this.Selectvalue);
    },
    handleDateTimeFocus() {
      console.log("datetime:focused")
      setTimeout(() => {
        this.$emit("focused");
        this.timerPickerFocus = true;
      },300)
    },
    handleDateTimeBlur(){
      console.log("datetime:blurred")
      setTimeout(() => {
        this.$emit("blurred");
        this.timerPickerFocus = false;
      },300)
    },
    bargeclick(target) {
      this.dialogVisible = true;
      this.dialogVisible = true;
      let result = this.bargeoptions.find(
        (item) => item.imo == target.bargeName
      );
      this.$nextTick(() => {
        this.shipinfo = result;
        this.vesselName = target.vesselName;
      });
    },
    getData() {
      getSelectVessel().then((res) => {
        if (res.code == 200) {
          this.bargeoptions = res.data;
        }
      });
    },
    getCellStyle({ rowIndex }) {
      if (rowIndex % 2 === 1) {
        return {
          backgroundColor: "rgba(255,255,255,0.2)",
          cursor: 'pointer'
        };
      } else {
        return {
          backgroundColor: "rgba(228,228,228,0.2)",
          cursor: 'pointer'
        };
      }
    },
    // 点击某一行
    Tanbleclick(row, column, event) {
      let target = event.target;
      if (!target.classList.contains("bargeNameLink") && !this.timerPickerFocus) {
        this.$emit("Tanbleclick", row);
         
        if( this.$store.state.userInfo.userType == '1' && 
        ['10','12'].includes(this.$store.state.userInfo.role) && 
            this.pilotTabs.includes( this.orderIndex ) 
        ) {
          this.$router.push({ path: "/pilot/index", query: { id: row.id, pageStatus: 1 } });
        } else  {
          this.$router.push({
            path: "/barging/index",
            query: {
              id: row.id,
              pageStatus: 1,
            },
          });
        }
        sessionStorage.setItem("pageStatus", 1);
        sessionStorage.setItem("editData", JSON.stringify(row));
      }
    },
    handleRefresh() {
      this.$emit("refreshOnClick");
    },
    handleSelectChange() {
      this.$emit("updateList", this.Tankinput, this.Selectvalue);
    },
    getJettyList(){
      JettyAll().then(res => {
        this.jettyNoOptios = res.rows;
      })
    },
    handleJettyOptionChange(item){
      if (this.source) {
        this.source.cancel();
      }
      const CancelToken = axios.CancelToken;
      this.source = CancelToken.source();
      this.$emit("focused");

      const sumbitFn = () => {
        Amendnomination({
          jettyNo:item['jettyNo'],
          berthAssignTiming:item.berthAssignTiming,
          id:item['id'],
        }, this.source.token).then(() => {
          this.$message.success('success');
          this.$emit("blurred");
          this.$emit("updateList", this.Tankinput, this.Selectvalue);
        })
      }
      searchBargeBer({
        berthName: item.jettyNo,
        lengthOverall:item.lengthOverall
      }).then(result => {
        if (result.code == 200) {
          sumbitFn();
        } else {
          this.message = result.msg;
          this.effectCallback = sumbitFn;
          this.$refs.checkBargeDialog.open();
        }
      })


    },
    Timehangder(item){
      if (this.source) {
        this.source.cancel();
      }
      const CancelToken = axios.CancelToken;
      this.source = CancelToken.source();
      this.$emit("focused");

      let a = item.berthAssignTiming.split("/");
      let b = a[2].slice(0, 4);
      let d = a[2].slice(4);
      let c = b + "/" + a[1] + "/" + a[0] + d;
      Amendnomination({
        berthAssignTiming: new Date(c).valueOf(),
        jettyNo:item['jettyNo'],
        id:item['id'],
      }, this.source.token).then(() => {
        this.$message.success('success');
        this.$emit("blurred");
        this.$emit("updateList", this.Tankinput, this.Selectvalue);
      })
    }
  },
};
</script>

<style scoped lang="scss">
.Topinp {
  // position: relative;
  background-color: rgb(242, 247, 250);
  
  .Topinput {
    display: flex;
    padding: 10px;
    .Pendingselect {
      margin-left: 20px;
      width: 100%;
    }
  }
}
.box-card {
  // margin-top: 12px;
}

.placeholder {
  height: 66px;
  background-color: #fff;
}
.Comtable {
  // height: calc(88vh - 60px - 32 * 2px - 30px);
  overflow-y: auto;
  .el-table {
    font-size: 12px;
    .bargeNameLink {
      color: #285aff;
      font-size: 11px;
      text-decoration-line: underline;
      color: #285aff;
      cursor: pointer;
    }
    div{
      word-break:keep-all;
    }
  }
}
::v-deep .el-table__row > .el-table__cell > .cell {
  font-size: 10px;
}
::v-deep.el-table th.el-table__cell > .cell {
  font-size: 11px;
  color: #1D1B20 !important;
  // padding-left: 25px;
}
::v-deep .el-input__suffix-inner {
  padding-right: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  svg {
    width: 13px;
    height: 13px;
    cursor: pointer;
  }
}
::v-deep .el-table th.el-table__cell.is-leaf{
  padding: 0px;
}
::v-deep .bargename-header .cell{
  display: flex;
  align-items: center;
}
::v-deep .bargename-header .caret-wrapper{
  margin-left: 15%;
  margin-top: -20%;
}
.mobile-view{
    ::v-deep .el-table th.el-table__cell > .cell {
    font-size: 8px;
    line-height:9px;
    color: #1D1B20 !important;
  }

  .bargeNameLink {
    font-size: 8px!important;
    line-height:9px!important;
  }
  ::v-deep .el-table__row > .el-table__cell{
  padding:6px 0!important;
  }
  ::v-deep .el-table__row > .el-table__cell > .cell {
    font-size: 7px;
    line-height:8px; 
  }
  ::v-deep .Topinput{
    // padding-bottom: 2px;
  }
  .Comtable{
    ::v-deep .el-table__cell > .cell{
      padding-left: 3px;
      padding-right: 2px;
      b{
        word-break:keep-all;
      }
    }
    ::v-deep th.el-table__cell > .cell{
      padding-left: 3px;
      padding-right: 1px;
      b{
        word-break:keep-all;
      }
    }
    .el-select--mini{
      ::v-deep .el-input__inner{
        font-size: 10px;
        padding-right:5px!important;
        padding-left:5px!important;
      }
    }
    .dataPicker{
      ::v-deep .el-input__inner{
        font-size: 10px;
        padding-right:5px!important;
        padding-left:25px!important;
      }
    }
  }
  ::v-deep .bargename-header .caret-wrapper{
  margin-left: 10%;
  margin-top: -10%;
}
}
::v-deep .el-table__row > .el-table__cell > .cell {
  color: #1D1B20 !important;
}
::v-deep .el-input--small .el-input__inner, .refreshBtn {
  border: 1px solid #82889C;
  color: #82889C;
}
::v-deep .el-select .el-input .el-select__caret {
  color: #82889C;
}
::v-deep .el-table .sort-caret.descending {
  border-top-color: #1D1B20;
}
::v-deep .el-table .sort-caret.ascending {
  border-bottom-color: #1D1B20;
}
::v-deep .el-table__row td {
  border-bottom: 1px solid #D5DDED;
}
@media (max-width: 1023px) {
  .Comtable {
    height: calc(100vh - 16rem);
  }
  .dynamicHeight{
    height: calc(100vh - 19rem);
  }

  .mobile-view{
    .Comtable {
      height: calc(100vh - 15.5rem);
    }
    .dynamicHeight{
      height: calc(100vh - 18rem);
    }
  }
}
@media (min-width: 1024px) {
  .Comtable {
    height: calc(100vh - 14rem);
  }
  .dynamicHeight{
    height: calc(100vh - 16.5rem);
  }
}

</style>
