<template>
  <el-dialog
    class="successDialog"
    :visible.sync="show"
    :width="isMobileScreen?'300px':'580px'"
    :show-close="false"
  >
    <div class="text">
        {{ message }}
        <!-- The assigned barge's LOA is lower than the minimum LOA of the berth. -->
      </div>
    <div class="text">
      Confirm to submit?
    </div>
    <div class="flex justify-center mt-5">
      <el-button
        size="small"
        type="primary"
        @click="Confirm"
        >{{ $t("btn.confirm") }}</el-button
      >
      <el-button size="small" class="clo" type="primary" @click="close">{{
        $t("btn.close")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name:"checkBargeDialog",
  props:['message','isMobileScreen'],
  data() {
    return {
      show: false,
    };
  },
  created() {
  },
  methods: {
    open(val) {
      this.show = true;
    },
    Confirm() {
      this.$emit('confirm');
    },
    close() {
      this.show = false;
      // this.$emit("upup", this.userTypes);
    },
  },
};
</script>

<style lang="scss">
.successDialog {
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 30px 20px;
  }
  .el-dialog {
    border-radius: 10px;
  }
  .text {
    text-align: center;
    color: #82889c;
    font-size: 15px;
    word-break: keep-all;
  }
  .message {
    font-size: 18px;
    text-align: center;
    color: #82889c;
    margin-top: 10px;
  }
  .clo {
    background: #fff;
    color: #008CDB;
  }
}
</style>
