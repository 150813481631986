<template>
  <div class="shipContainer" v-loading="shiploading">
    <el-tabs v-model="activeTab">
      <el-tab-pane :label="$t('common.shipInformation')" name="shipInformation">
        <div class="information flex mobile:flex-wrap">
          <div class="left flex flex-col w-full">
            <el-image :src="shipinfomation" />
            <span v-if="data && data.bargeName">{{ data.bargeName }} </span>
          </div>
          <div class="right flex-1 pb-3">
            <div class="flex h-6" style="margin-top: 0">
              <span class="left-text"
                >{{ $t("common.vesselIMONumber") }} :</span
              >
              <span class="left-value" v-if="data && data.imo">{{ data.imo }}</span>
            </div>
            <div class="flex h-6">
              <span class="left-text"
                >{{ $t("common.iMOCompanyNumber") }}:
              </span>
              <span class="left-value" v-if="data && data.companyNumber">{{ data.companyNumber }}</span>
            </div>
            <div class="flex h-6">
              <span class="left-text">{{ $t("common.speed") }}: </span>
              <span class="left-value" v-if="data && data.speed">{{ data.speed }}</span>
            </div>
            <div class="flex h-6">
              <span class="left-text">{{ $t("common.course") }}: </span>
              <span class="left-value" v-if="data && data.course">{{ data.course }}</span>
            </div>
            <div class="flex h-6">
              <span class="left-text">{{ $t("common.draught") }}: </span>
              <span class="left-value" v-if="data && data.draught">{{ data.draught }}</span>
            </div>
            <div class="flex h-6">
              <span class="left-text">{{ $t("common.status") }}: </span>
              <span class="left-value" v-if="data && data.status">{{ data.status }}</span>
            </div>
            <div class="flex h-6">
              <span class="left-text">{{ $t("common.lastReport") }}: </span>
              <span class="left-value" v-if="data && data.lastReport">{{ data.lastReport }}</span>
            </div>
            <div class="flex h-6">
              <span class="left-text flex items-center">{{ $t("common.lengthOverall") }}: </span>
              <span class="left-value">
                <el-form :model="shipInfoForm" ref="shipInfoForm">
                  <el-form-item 
                    class="m-0"
                    :rules="{
                      required: true, 
                      validator: lengthOverallValidator, 
                      trigger: ['blur', 'change']
                    }" 
                    prop="lengthOverall"
                  >
                    <el-input-number v-model.number="shipInfoForm.lengthOverall" :controls="false" :min="0"
                      size="small" :disabled="status >= 3 || !isOps">
                      <div slot="append">m</div>
                    </el-input-number>
                  </el-form-item>
                </el-form>
              </span>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('common.ShipAISLocation')" name="ShipAISLocation" v-if="data.imo">
        <iframe :key="iframeKey" id="vesselFinderRef" :src="'https://www.vesselfinder.com/aismap?&amp;names=true&amp;lat=0&amp;lon=0&amp;zoom=3&amp;imo='+ data.imo +'&amp;track=true&amp;clicktoact=false'" width="100%" height="400" frameborder="0">
          Browser does not support embedded objects.<br/>Visit directly <a href="https://www.vesselfinder.com" target="_blank">www.vesselfinder.com</a>
        </iframe>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon.vue";
import mapViewImg from "@/assets/map-view.png";
import shipinfomation from "@/assets/shipinfomation.png";
export default {
  components: { SvgIcon },
  props: {
    // form的传值
    shipinfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    vesselName: {
      type: String,
      default: "",
    },
    status: {
      type: Number,
    }
  },
  data() {
    return {
      activeTab: "shipInformation",
      mapViewImg,
      data: {},
      shipinfomation,
      shiploading: false,
      shipInfoForm: {
        lengthOverall: null
      },
      iframeKey: null
    };
  },
  computed: {
    isOps: function() {
      return this.$store.state.userInfo.userType == 1 && this.$store.state.userInfo.role == 2;
    }
  },
  methods: {
    isNumberWithDecimal(value) {
      if (typeof value === 'number') return true;
      return false;
    },
    lengthOverallValidator (rule, value, callback) {
      if (!this.isNumberWithDecimal(value)) {
        callback(new Error('Please input numbers only'));
      } 
      if (value === 0) {
        callback(new Error('Length overall must be greater than 0'));
      }
      if (!value) {
        callback(new Error(''));
      }
      callback();
    },
  },
  watch: {
    shipinfo(val) {
      
      this.data = val;
      this.shipInfoForm.lengthOverall = this.data.lengthOverall
      this.shiploading = false;
      if (document.querySelector("#vesselFinderRef") != null) {
        this.iframeKey++;
      }
    },
    'shipInfoForm.lengthOverall' (val) {
      if (this.isNumberWithDecimal(val)) sessionStorage.setItem('lengthOverall', val)
    },
  },
};
</script>

<style lang="scss" scoped>
.shipContainer {
  background: #ffffff;
  box-shadow: 0px 4px 4px 1px rgba(196, 196, 196, 0.1);
  border-radius: 2px;
  .information {
    padding: 36px 36px;
    border-top: 4px solid #fafafa;
    .left {
      width: 140px;
      ::v-deep .el-image {
        width: 140px;
        height: 140px;
        border-radius: 50%;
      }
      > span {
        font-size: 14px;
        text-align: center;
        color: #82889c;
        margin-top: 28px;
      }
    }
    .right {
      margin-left: 30px;
      > div {
        margin-top: 20px;
      }
      .left-text {
        width: 230px;
        font-size: 13px;
        font-weight: 600;
        color: #82889c;
      }
      .left-value {
        width: 100%;
        font-size: 13px;
        color: #82889c;
      }
    }
  }
  .ShipAISLocation {
    height: 322px;
    border-top: 4px solid #fafafa;
    padding: 24px;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      background: rgba(255, 147, 0, 0.2);
      border-radius: 50%;
      .wave {
        height: 3px;
      }
    }
    .arrow-right {
      margin: 0 22px;
    }
  }
}
::v-deep .el-tabs__nav-wrap::after {
  background: transparent;
}
::v-deep .el-tabs__header {
  padding: 15px 22px;
  margin: 0;
}
::v-deep .el-tabs__item {
  font-size: 15px;
  color: rgba(130, 136, 156, 0.6);
}
::v-deep .el-tabs__item.is-active {
  color: $primaryColor;
  font-weight: bold;
}
::v-deep .el-tabs__item {
  padding: 0 15px;
}
::v-deep .el-input-number .el-input__inner {
  text-align: left;
}
.no-error-message{
  ::v-deep .el-form-item__error {
    display: none;
  }
}
</style>
