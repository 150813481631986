<template>
  <div ref="container" class="mapView overflow-x-auto overflow-y-hidden "  v-bind:class="{'map-mobile-view':screenType.isMobileScreen}" :style="{width:screenType.isMobileScreen ? `${screenType.mainLayoutWidth *2 -30}px`:'100%',marginLeft:screenType.isMobileScreen ?`-${screenType.mainLayoutWidth/2}px`:0,marginTop:screenType.isMobileScreen ?'-150px':0}">
    <canvas ref="myCanvas"></canvas>
    <div class="bg"></div>
    <!-- eslint-disable-next-line vue/require-v-for-key -->
    <div
      class="item"
      v-for="item in jettyPositionStyle"
      :style="item.cardPositon"
      @click="handleItemClick(item)"
    >
      <div v-if="jettyData[item.id]?.[0] || jettyData[item.id]?.[1]">
        <div>
          <span class="dot"></span>
          <span class="time">{{getDisplayTime(showTaget(jettyData[item.id])["berthAssignTiming"])}}</span>
        </div>
        <span class="name">{{
          showTaget(jettyData[item.id])["companyName"]
        }}</span>
      </div>
      <div class="notData" v-else>Not Assigned</div>
    </div>
    <!-- eslint-disable-next-line vue/require-v-for-key -->
    <div
      class="number"
      v-for="item in jettyPositionStyle"
      :style="{
        ...item.numerPosition,
        background:
          jettyData[item.id]?.[0] || jettyData[item.id]?.[1]
            ? '#008CDB'
            : '#2DC12A',
      }"
    >
      {{ item.num }}
    </div>
    <!-- eslint-disable-next-line vue/require-v-for-key -->
    <div
      class="line"
      v-for="item in jettyPositionStyle"
      :style="item.linePosition"
    ></div>
    </div>
</template>
<script>
import mapViewSrc from "@/assets/map-view-preview.png";
import mapLineBg from "@/assets/map-line.png";
import mapYellowBg from "@/assets/map-yellow-bg.png";
import { mapState } from "vuex";
export default {
  name: "mapView",
  data() {
    return {
      mapViewSrc,
      ctx: null,
      width: null,
      imageStationHeight: 257,
      height: 600,
      jettyPositionStyle: {
        "Berth 3": {
          cardPositon: {
            top: "517px",
            left: "816px",
          },
          numerPosition: {
            top: "458px",
            left: "867px",
          },
          linePosition: {
            width: "22px",
            left: "886px",
            top: "518px",
            transform: "rotate(-90deg)",
          },
          id: "3",
          num: "3",
        },
        "Berth 5": {
          cardPositon: {
            top: "309px",
            left: "323px",
          },
          numerPosition: {
            top: "380px",
            left: "387px",
          },
          linePosition: {
            width: "30.53px",
            left: "398px",
            top: "384px",
            transform: "rotate(-121.61deg)",
          },
          id: "5",
          num: "5",
        },
        "Berth 6": {
          cardPositon: {
            top: "300px",
            left: "520px",
          },
          numerPosition: {
            top: "372px",
            left: "578px",
          },
          linePosition: {
            left: "595px",
            top: "374px",
            width: "27.92px",
            transform: "rotate(-107.53deg)",
          },
          id: "6",
          num: "6",
        },
        "Berth 7": {
          cardPositon: {
            top: "300px",
            left: "716px",
          },
          numerPosition: {
            left: "769px",
            top: "374px",
          },
          linePosition: {
            width: "29px",
            left: "786px",
            top: "375px",
            transform: "rotate(-107.53deg)",
          },
          id: "7",
          num: "7",
        },
        "Berth 8": {
          cardPositon: {
            left: "400px",
            top: "34px",
          },
          // cardPositon: {
          //   left: "26.67%",
          //   top: "6%",
          // },
          numerPosition: {
            left: "463px",
            top: " 142.1px",
          },
          linePosition: {
            width: "63px",
            height: "2px",
            left: "495px",
            top: " 80px",
            transform: "rotate(97.43deg)",
          },
          id: "8",
          num: "8",
        },
        "Berth 9": {
          cardPositon: {
            left: "560px",
            top: "34px",
          },
          numerPosition: {
            width: "40.25px",
            height: " 40.25px",
            left: "570px",
            top: "138.92px",
          },
          linePosition: {
            width: "100px",
            height: "2px",
            left: "661px",
            top: "80px",
            transform: "rotate(141.71deg)",
          },
          id: "9",
          num: "9",
        },
        "Berth 10": {
          cardPositon: {
            left: "720px",
            top: "34px",
          },
          numerPosition: {
            left: "677px",
            top: "137.74px",
          },
          linePosition: {
            width: "148px",
            left: "833px",
            top: "79px",
            transform: "rotate(156deg)",
          },
          id: "10",
          num: "10",
        },
        "Berth 11": {
          cardPositon: {
            left: "1294px",
            top: "323px",
          },
          numerPosition: {
            left: "1216px",
            top: "311px",
          },
          linePosition: {
            width: "42px",
            left: "1294px",
            top: "351px",
            transform: " rotate(-159.86deg)",
          },
          id: "11",
          num: "11",
        },
        "Berth 13": {
          cardPositon: {
            left: "1400px",
            top: "131px",
          },
          numerPosition: {
            left: "1333px",
            top: "131px",
          },
          linePosition: {
            width: "30px",
            left: "1402px",
            top: "158px",
            transform: "rotate(-180deg)",
          },
          id: "13",
          num: "13",
        },
        "Berth 15": {
          cardPositon: {
            left: "240px",
            top: "34px",
          },
          // cardPositon: {
          //   left: "15.94%",
          //   top: "6.33%",
          // },
          numerPosition: {
            left: "356px",
            top: " 142.1px",
          },
          linePosition: {
            width: "74.25px",
            height: "2px",
            left: "326px",
            top: "83px",
            transform: "rotate(55.63deg)",
          },
          id: "15",
          num: "15",
        },
        "Berth 16": {
          cardPositon: {
            left: "80px",
            top: "34px",
          },
          // cardPositon: {
          //   left: "5.21%",
          //   top: "6.67%",
          // },
          numerPosition: {
            left: "249px",
            top: " 142.1px",
          },
          linePosition: {
            width: "116.53px",
            height: "2px",
            left: "157px",
            top: "85px",
            transform: "rotate(33.13deg)",
          },
          id: "16",
          num: "16",
        },
        "Berth 17": {
          cardPositon: {
            left: "127px",
            top: "276px",
          },
          numerPosition: {
            left: "190px",
            top: "206px",
          },
          linePosition: {
            width: "33px",
            left: "200px",
            top: "277px",
            transform: "rotate(-82.57deg)",
          },
          id: "17",
          num: "17",
        },
      },
    };
  },
  props: {
    jettyData: {
      type: Object,
      default() {
        return {
          "3": [],
          "5": [],
          "6": [],
          "7": [],
          "8": [],
          "9": [],
          "10": [],
          "11": [],
          "13": [],
          "15": [],
          "16": [],
          "17": [],
        };
      },
    },
  },
  created() {},
  methods: {
    getDisplayTime(val){
      if (!val || val==='') return '';
     var index =  val.lastIndexOf(':')
      return val.substring(0,index)
    },
    showTaget(item) {
      if (item[0] && !item?.[1]) {
        return item[0];
      }
      if (item[1] && !item?.[0]) {
        return item[1];
      }
      if (item[0] && item[1]) {
        return item[0];
      }
    },
    initCanvas() {
      let container = this.$refs.container;
      let width = container.clientWidth;
      this.width = width;
      // this.width = '1540';
      this.ctx = this.$refs.myCanvas.getContext("2d");
      this.ctx.canvas.width = width;
      this.ctx.canvas.height = this.height;
      this.ctx.clearRect(0, 0, this.width, this.height);
      this.drawBg();
      this.drawLine();
    },
    async drawBg() {
      let imageStation = await this.addImageProcess(
        mapYellowBg,
        this.width * 0.83,
        this.imageStationHeight
      );
      this.ctx.drawImage(imageStation, 0, 0);
      let imageLine = await this.addImageProcess(
        mapLineBg,
        this.width * 0.83,
        this.imageStationHeight
      );
      this.ctx.drawImage(imageLine, 0, 0);
    },
    drawLine() {
      this.ctx.lineWidth = 5;
      let ctx = this.ctx;
      ctx.strokeStyle = "white";
      ctx.beginPath();
      ctx.moveTo(820, 240);
      ctx.lineTo(840, 445);
      ctx.stroke();
      ctx.moveTo(380, 455);
      ctx.lineTo(1000, 440);
      ctx.stroke();
      ctx.moveTo(1150, 130);
      ctx.lineTo(1250, 200);
      ctx.stroke();
      ctx.moveTo(1342, 70);
      ctx.lineTo(1122, 370);
      ctx.stroke();
      ctx.closePath();
    },
    drawJettyDot() {},
    addImageProcess(src, width, height) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.width = width;
        img.height = height;
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
      });
    },
    handleItemClick(item){
      let target = this.jettyData[item.id]?.[0] || this.jettyData[item.id]?.[1];
      if(target){
        this.$router.push({
        path: "/barging/index",
        query: {
          id: target.bId,
          pageStatus: 1,
        },
      });
      }
    }
  },
  mounted() {
    this.initCanvas();
  },
  computed: {
    ...mapState(["screenType"]),
 
  },
};
</script>
<style lang="scss" scoped>
.mapView {
  // width: 100%;
  position: relative;
  height: 600px;
  
  canvas {
    position: absolute;
    z-index: 2;
  }
  .bg {
    position: absolute;
    z-index: 1;
    width: 100%;
    min-width: 1560px;
    height: 600px;
    background: linear-gradient(
      309.88deg,
      #177b9d 8.29%,
      rgba(145, 226, 255, 0.52) 103.93%
    );
    box-shadow: 0px 4px 4px 2px rgba(196, 196, 196, 0.1);
  }
}
.item {
  width: 150px;
  position: absolute;
  padding: 5px 8px;
  background: #ffffff;
  box-shadow: 0px 4px 4px 2px rgba(196, 196, 196, 0.4);
  z-index: 4;
  .time {
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    padding-left: 5px;
    color: #4c565c;
  }
  .dot {
    display: inline-block;
    width: 11px;
    height: 11px;
    left: 432px;
    border-radius: 50%;
    background: #008CDB;
  }
  .name {
    font-size: 12px;
    line-height: 14px;
    color: #4c565c;
  }
}
.number {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40.25px;
  height: 40.25px;

  border-radius: 50%;
  background: #2dc12a;
  color: white;
  border: 2px solid #ffffff;
  z-index: 3;
}
.line {
  position: absolute;
  transform-origin: 0 0;
  z-index: 3;
  height: 2px;
  background: white;
}
.notData {
  font-size: 12px;
  /* identical to box height */
  text-align: center;
  line-height: 48px;
  padding: 0;
  color: #4c565c;
  width: 118px;
}

.map-mobile-view {
  // width:930px;
  scale: 50%;
  // position: relative;
  // height: 600px;
  // margin-left:-240px ;
  
  canvas {
    // position: absolute;
    // z-index: 2;
  }
  .bg {
    // position: absolute;
    // z-index: 1;
    // width: 100%;
    // min-width: 1540px;
    // height: 600px;
  }
}
</style>